









































import { Component, Prop, Emit, Vue, Watch } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';
import LocalDataService from '../../service/LocalDataServices';

const i18n = new VueI18n({
  locale: LocalDataService.getLocale(),
  messages: {
    en: {
      resendAuthentication: 'Resend Authemtication Mail',
      returnToSignUp: 'Return to Sign Up Page',
    },
    ja: {
      resendAuthentication: '認証メールを再送信する',
      returnToSignUp: 'サインアップ画面に戻る',
    },
  },
});

@Component({
  i18n,
  components: {
  },
})
export default class ErrorPage extends Vue {

  public jaStyle = {color : 'black', textDecoration : 'none'};
  public enStyle = {color : '#00AF74', textDecoration : 'underLine'};

  @Prop({ default: '' }) public errorMessage!: string;
  @Prop({ default: false }) public isExpired!: boolean;
  @Prop({ default: 'ja' }) public locale!: string;

  public mounted() {
    this.$i18n.locale = this.locale;
    if (this.locale === 'ja') {
      this.jaStyle = {color : 'black', textDecoration : 'none'};
      this.enStyle = {color : '#00AF74', textDecoration : 'underLine'};
    } else {
      this.enStyle = {color : 'black', textDecoration : 'none'};
      this.jaStyle = {color : '#00AF74', textDecoration : 'underLine'};
    }
  }

  @Watch('locale')
  public onLocaleChanged(locale: string) {
    this.$i18n.locale = locale;
    if (locale === 'ja') {
      this.jaStyle = {color : 'black', textDecoration : 'none'};
      this.enStyle = {color : '#00AF74', textDecoration : 'underLine'};
    } else {
      this.enStyle = {color : 'black', textDecoration : 'none'};
      this.jaStyle = {color : '#00AF74', textDecoration : 'underLine'};
    }
  }

  @Emit('selectJa')
  public selectJa() {
    this.$i18n.locale = 'ja';
    this.inputAuthLocale('ja');
  }

  @Emit('selectEn')
  public selectEn() {
    this.$i18n.locale = 'en';
    this.inputAuthLocale('en');
  }

  @Emit('inputAuthLocale')
  public inputAuthLocale(locale: string) {
    return locale;
  }
}
