import { Module, VuexModule, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store/index';

export interface ErrorState {}

@Module({ dynamic: true, store, name: 'error', namespaced: true })
class ErrorModule extends VuexModule implements ErrorState {

  public errorType: string = '';
  public locale: string = 'ja';

  public get errorMessage() {
    if (this.errorType === 'M001') {
      if (this.locale === 'en') {
        return 'Please input team IDVerification code has been expired.(M001)';
      }
      return 'メール認証リンクの期限が切れています。(M001)';
    } else if (this.errorType === 'M002') {
      if (this.locale === 'en') {
        return 'Please input team ID';
      }
      return 'Other error occured, please try again.(M002)';
    } else {
      if (this.locale === 'en') {
        return 'Some error occured, please try again.';
      }
      return '何らかのエラーが発生しました。再度お試しください。';
    }
  }

  public get isExpired() {
    return this.errorType === 'M001';
  }

  @Mutation
  public setErrorType(errorType: string) {
    this.errorType = errorType;
  }
}

export const errorModule = getModule(ErrorModule);
