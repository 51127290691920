








import { Component, Prop, Vue } from 'vue-property-decorator';
import ErrorPage from '@/components/pages/ErrorPage.vue';
import { errorModule } from '../store/viewModule/common/errorModule';
import VueI18n from 'vue-i18n';
import LocalDataService from '../service/LocalDataServices';

const i18n = new VueI18n({
  locale: LocalDataService.getLocale(),
  messages: {
    en: {
    },
    ja: {
    },
  },
});

@Component({
  i18n,
  components: {
    ErrorPage,
  },
})
export default class ErrorContainer extends Vue {

  public get locale() {
    return this.$i18n.locale;
  }

  public get isExpired() {
    return errorModule.isExpired;
  }

  public get errorMessage() {
    return errorModule.errorMessage;
  }

  public inputAuthLocale(locale: string) {
    this.$i18n.locale = locale;
  }

  public mounted() {
    const errorType = this.$route.query.error_type as ( string | null );
    if (errorType) {
      errorModule.setErrorType(errorType);
    }
  }
}
